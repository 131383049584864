/**
 * @fileoverview gRPC-Web generated client stub for product
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.19.1
// source: waitlist.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.product = require('./waitlist_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.product.ProductServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.product.ProductServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.SubscribeRequest,
 *   !proto.product.SubscribeResponse>}
 */
const methodDescriptor_ProductService_Subscribe = new grpc.web.MethodDescriptor(
  '/product.ProductService/Subscribe',
  grpc.web.MethodType.UNARY,
  proto.product.SubscribeRequest,
  proto.product.SubscribeResponse,
  /**
   * @param {!proto.product.SubscribeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.SubscribeResponse.deserializeBinary
);


/**
 * @param {!proto.product.SubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.SubscribeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.SubscribeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.ProductServiceClient.prototype.subscribe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.ProductService/Subscribe',
      request,
      metadata || {},
      methodDescriptor_ProductService_Subscribe,
      callback);
};


/**
 * @param {!proto.product.SubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.SubscribeResponse>}
 *     Promise that resolves to the response
 */
proto.product.ProductServicePromiseClient.prototype.subscribe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.ProductService/Subscribe',
      request,
      metadata || {},
      methodDescriptor_ProductService_Subscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.AddProductRequest,
 *   !proto.product.AddProductResponse>}
 */
const methodDescriptor_ProductService_AddProduct = new grpc.web.MethodDescriptor(
  '/product.ProductService/AddProduct',
  grpc.web.MethodType.UNARY,
  proto.product.AddProductRequest,
  proto.product.AddProductResponse,
  /**
   * @param {!proto.product.AddProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.AddProductResponse.deserializeBinary
);


/**
 * @param {!proto.product.AddProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.AddProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.AddProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.ProductServiceClient.prototype.addProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.ProductService/AddProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_AddProduct,
      callback);
};


/**
 * @param {!proto.product.AddProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.AddProductResponse>}
 *     Promise that resolves to the response
 */
proto.product.ProductServicePromiseClient.prototype.addProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.ProductService/AddProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_AddProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.ListProductsRequest,
 *   !proto.product.ListProductsResponse>}
 */
const methodDescriptor_ProductService_ListProducts = new grpc.web.MethodDescriptor(
  '/product.ProductService/ListProducts',
  grpc.web.MethodType.UNARY,
  proto.product.ListProductsRequest,
  proto.product.ListProductsResponse,
  /**
   * @param {!proto.product.ListProductsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.ListProductsResponse.deserializeBinary
);


/**
 * @param {!proto.product.ListProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.ListProductsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.ListProductsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.ProductServiceClient.prototype.listProducts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.ProductService/ListProducts',
      request,
      metadata || {},
      methodDescriptor_ProductService_ListProducts,
      callback);
};


/**
 * @param {!proto.product.ListProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.ListProductsResponse>}
 *     Promise that resolves to the response
 */
proto.product.ProductServicePromiseClient.prototype.listProducts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.ProductService/ListProducts',
      request,
      metadata || {},
      methodDescriptor_ProductService_ListProducts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.UnsubscribeRequest,
 *   !proto.product.UnsubscribeResponse>}
 */
const methodDescriptor_ProductService_Unsubscribe = new grpc.web.MethodDescriptor(
  '/product.ProductService/Unsubscribe',
  grpc.web.MethodType.UNARY,
  proto.product.UnsubscribeRequest,
  proto.product.UnsubscribeResponse,
  /**
   * @param {!proto.product.UnsubscribeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.UnsubscribeResponse.deserializeBinary
);


/**
 * @param {!proto.product.UnsubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.UnsubscribeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.UnsubscribeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.ProductServiceClient.prototype.unsubscribe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.ProductService/Unsubscribe',
      request,
      metadata || {},
      methodDescriptor_ProductService_Unsubscribe,
      callback);
};


/**
 * @param {!proto.product.UnsubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.UnsubscribeResponse>}
 *     Promise that resolves to the response
 */
proto.product.ProductServicePromiseClient.prototype.unsubscribe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.ProductService/Unsubscribe',
      request,
      metadata || {},
      methodDescriptor_ProductService_Unsubscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.UnsubscribeChallangeRequest,
 *   !proto.product.UnsubscribeChallangeResponse>}
 */
const methodDescriptor_ProductService_UnsubscribeChallange = new grpc.web.MethodDescriptor(
  '/product.ProductService/UnsubscribeChallange',
  grpc.web.MethodType.UNARY,
  proto.product.UnsubscribeChallangeRequest,
  proto.product.UnsubscribeChallangeResponse,
  /**
   * @param {!proto.product.UnsubscribeChallangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.UnsubscribeChallangeResponse.deserializeBinary
);


/**
 * @param {!proto.product.UnsubscribeChallangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.UnsubscribeChallangeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.UnsubscribeChallangeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.ProductServiceClient.prototype.unsubscribeChallange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.ProductService/UnsubscribeChallange',
      request,
      metadata || {},
      methodDescriptor_ProductService_UnsubscribeChallange,
      callback);
};


/**
 * @param {!proto.product.UnsubscribeChallangeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.UnsubscribeChallangeResponse>}
 *     Promise that resolves to the response
 */
proto.product.ProductServicePromiseClient.prototype.unsubscribeChallange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.ProductService/UnsubscribeChallange',
      request,
      metadata || {},
      methodDescriptor_ProductService_UnsubscribeChallange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.ManageSubscriptionsRequest,
 *   !proto.product.ManageSubscriptionsResponse>}
 */
const methodDescriptor_ProductService_ManageSubscriptions = new grpc.web.MethodDescriptor(
  '/product.ProductService/ManageSubscriptions',
  grpc.web.MethodType.UNARY,
  proto.product.ManageSubscriptionsRequest,
  proto.product.ManageSubscriptionsResponse,
  /**
   * @param {!proto.product.ManageSubscriptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.ManageSubscriptionsResponse.deserializeBinary
);


/**
 * @param {!proto.product.ManageSubscriptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.ManageSubscriptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.ManageSubscriptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.ProductServiceClient.prototype.manageSubscriptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.ProductService/ManageSubscriptions',
      request,
      metadata || {},
      methodDescriptor_ProductService_ManageSubscriptions,
      callback);
};


/**
 * @param {!proto.product.ManageSubscriptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.ManageSubscriptionsResponse>}
 *     Promise that resolves to the response
 */
proto.product.ProductServicePromiseClient.prototype.manageSubscriptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.ProductService/ManageSubscriptions',
      request,
      metadata || {},
      methodDescriptor_ProductService_ManageSubscriptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.SubscribeProductRequest,
 *   !proto.product.SubscribeProductResponse>}
 */
const methodDescriptor_ProductService_SubscribeProduct = new grpc.web.MethodDescriptor(
  '/product.ProductService/SubscribeProduct',
  grpc.web.MethodType.UNARY,
  proto.product.SubscribeProductRequest,
  proto.product.SubscribeProductResponse,
  /**
   * @param {!proto.product.SubscribeProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.SubscribeProductResponse.deserializeBinary
);


/**
 * @param {!proto.product.SubscribeProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.SubscribeProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.SubscribeProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.ProductServiceClient.prototype.subscribeProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.ProductService/SubscribeProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_SubscribeProduct,
      callback);
};


/**
 * @param {!proto.product.SubscribeProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.SubscribeProductResponse>}
 *     Promise that resolves to the response
 */
proto.product.ProductServicePromiseClient.prototype.subscribeProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.ProductService/SubscribeProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_SubscribeProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.ListCategoriesRequest,
 *   !proto.product.ListCategoriesResponse>}
 */
const methodDescriptor_ProductService_ListCategories = new grpc.web.MethodDescriptor(
  '/product.ProductService/ListCategories',
  grpc.web.MethodType.UNARY,
  proto.product.ListCategoriesRequest,
  proto.product.ListCategoriesResponse,
  /**
   * @param {!proto.product.ListCategoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.ListCategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.product.ListCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.ListCategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.ListCategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.ProductServiceClient.prototype.listCategories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.ProductService/ListCategories',
      request,
      metadata || {},
      methodDescriptor_ProductService_ListCategories,
      callback);
};


/**
 * @param {!proto.product.ListCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.ListCategoriesResponse>}
 *     Promise that resolves to the response
 */
proto.product.ProductServicePromiseClient.prototype.listCategories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.ProductService/ListCategories',
      request,
      metadata || {},
      methodDescriptor_ProductService_ListCategories);
};


module.exports = proto.product;

