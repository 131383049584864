import {useEffect, useState} from 'react'

import {useForm} from 'react-hook-form'

import {uuid} from 'licia'

import Toastify from 'toastify-js'

import {DOMAIN} from '../config'

import {
  ProductServiceClient,
  AddProductRequest,
  ListCategoriesRequest,
} from '../grpc/generated/waitlist_grpc_web_pb'

import './ProductForm.scss'

import 'toastify-js/src/toastify.css'

const client = new ProductServiceClient(DOMAIN)

const toastifyOptions = {
  position: 'center',
  backgroundColor: 'linear-gradient(93.27deg, #364aff 22.47%, #2036ff 94.09%)',
  duration: 3000,
}

function ProductForm({showModal, onClose, closeMask = true}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    getCategoryList()
  }, [])

  const closeModal = e => {
    if (closeMask && e?.target?.classList?.contains?.('mask')) onClose()
  }

  const getCategoryList = () => {
    const request = new ListCategoriesRequest()
    request.setOffset(0)
    request.setLimit(100)
    client.listCategories(request, {}, (err, res) => {
      if (err?.message) Toastify({...toastifyOptions, text: err.message}).showToast()
      const data = res?.toObject()
      setCategoryList(data?.categoriesList ?? [])
    })
  }

  const onSubmit = data => {
    const request = new AddProductRequest()
    request.setName(data.name)
    request.setUrl(data.url)
    request.setDescription(data.description)
    request.setLogo(data.logo)
    request.setEmail(data.email)
    request.setCategoryList(data.categories)
    client.addProduct(request, {}, (err, res) => {
      if (err) Toastify({...toastifyOptions, text: err.message}).showToast()
      const data = res?.toObject()
      if (data) {
        Toastify({...toastifyOptions, text: 'Product submitted successfully.'}).showToast()
        onClose()
      }
    })
  }

  return (
    showModal && (
      <div
        className="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 z-100"
        onClick={closeModal}
      >
        <div
          className="form-container bg-white p-[5vw] w-[80vw] rounded-[2vw] max-h-80vh overflow-scroll"
          xl="p-[20px] w-[800px] rounded-[8px] max-h-[90vh] overflow-scroll"
        >
          <header className="form-header flex">
            <img
              className="close material-symbols-outlined w-[24px] h-[24px] ml-auto cursor-pointer"
              src={`${process.env.PUBLIC_URL}/close.svg`}
              onClick={() => onClose()}
            />
          </header>
          <form
            className="form flex flex-col"
            xl="max-w-[800px] items-left gap-[8px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label className="my-[2vw]" xl="my-[8px]">
              You New Product
            </label>
            <input
              className="py-2.5vw px-5vw text-base border border-black"
              xl="py-10px px-20px"
              {...register('name', {required: true, maxLength: 40})}
              autoComplete="off"
            />
            {errors?.name?.type === 'required' && (
              <p className="errors text-red-500">This field is required</p>
            )}
            {errors?.name?.type === 'maxLength' && (
              <p className="errors text-red-500">First name cannot exceed 40 characters</p>
            )}
            <label className="my-[2vw]" xl="my-[8px]">
              URL
            </label>
            <input
              className="py-2.5vw px-5vw text-base border border-black"
              xl="py-10px px-20px"
              {...register('url', {required: true, pattern: /^(https?:\/\/)?[^\s/$.?#].[^\s]*$/})}
              autoComplete="off"
            />
            {errors?.url?.type === 'required' && (
              <p className="errors text-red-500">This field is required</p>
            )}
            {errors?.url?.type === 'pattern' && (
              <p className="errors text-red-500">Alphabetical characters only</p>
            )}
            <label className="my-[2vw]" xl="my-[8px]">
              Logo
            </label>
            <input
              className="py-2.5vw px-5vw text-base border border-black"
              xl="py-10px px-20px"
              {...register('logo', {
                required: true,
              })}
              autoComplete="off"
            />
            {errors?.logo?.type === 'required' && (
              <p className="errors text-red-500">This field is required</p>
            )}
            <label className="my-[2vw]" xl="my-[8px]">
              Email
            </label>
            <input
              className="py-2.5vw px-5vw text-base border border-black"
              xl="py-10px px-20px"
              {...register('email', {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              autoComplete="off"
            />
            {errors?.email?.type === 'required' && (
              <p className="errors text-red-500">This field is required</p>
            )}
            {errors?.email?.type === 'pattern' && (
              <p className="errors text-red-500">
                Invalid email format. Please enter a valid email address.
              </p>
            )}
            <label className="my-[2vw]" xl="my-[8px]">
              Description
            </label>
            <textarea
              className="py-2.5vw px-5vw text-base border border-black"
              xl="py-10px px-20px"
              {...register('description', {
                required: true,
              })}
              autoComplete="off"
            />
            {errors?.description?.type === 'required' && (
              <p className="errors text-red-500">This field is required</p>
            )}
            <label className="my-[2vw]" xl="my-[8px]">
              Category
            </label>
            <div
              className="categories-box grid grid-cols-2 gap-[2vw] mb-[2vw]"
              xl="grid grid-cols-3 gap-[8px] mb-[8px]"
            >
              {categoryList.map(category => (
                <label key={uuid()} className="h-auto" xl="flex items-center">
                  <input
                    className="category flex-basis-3vw mr-[2vw]"
                    xl="w-[16px] h-[16px] mr-[8px]"
                    type="checkbox"
                    value={category}
                    {...register('categories', {required: true})}
                  />
                  {category}
                </label>
              ))}
            </div>
            <button
              className="submit mt-0 mb-0 mr-auto ml-auto h-[40px] w-[100px] flex justify-center items-center font-normal font-bold text-[14px] leading-[17px] text-white"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    )
  )
}

export default ProductForm
