import {useState, useEffect} from 'react'

import {useForm} from 'react-hook-form'

import Toastify from 'toastify-js'

import {uuid} from 'licia'

import {DOMAIN} from './config'

import {waitlistStore} from './store'

import ProductForm from './components/ProductForm'

import 'toastify-js/src/toastify.css'

import {
  ProductServiceClient,
  SubscribeRequest,
  ListProductsRequest,
} from './grpc/generated/waitlist_grpc_web_pb'

import './App.scss'

const toastifyOptions = {
  position: 'center',
  backgroundColor: 'linear-gradient(93.27deg, #364aff 22.47%, #2036ff 94.09%)',
  duration: 3000,
}

const client = new ProductServiceClient(DOMAIN)

function App() {
  const [productList, setProductList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [_userInfo, setUserInfo] = useState({})

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm()

  const onCloseFormModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    getProductList()
  }, [])

  useEffect(() => {
    async function getUserInfo() {
      const userInfo = await waitlistStore.getItem('userInfo')
      setUserInfo(userInfo)
      reset(userInfo)
    }
    getUserInfo()
  }, [reset])

  const subscribe = params => {
    const request = new SubscribeRequest()
    const [firstname, lastname] = (params?.name ?? '').split(' ')
    if (!lastname) {
      // 如果没有 lastname ，就取 firstname 的第一个字母 作为 lastname ，剩余的作为 firstname
      const firstChart = firstname[0]
      request.setLastname(firstChart)
      const restChart = firstname.slice(1)
      request.setFirstname(restChart)
    } else {
      request.setFirstname(firstname)
      request.setLastname(lastname)
    }
    request.setEmail(params.email)
    request.setEverything(true)
    request.setSignUpAllExisting(true)

    client.subscribe(request, {}, (err, res) => {
      if (err?.message) Toastify({...toastifyOptions, text: err.message}).showToast()
      const data = res?.toObject()
      if (data) {
        Toastify({
          text: 'Successfully subscribed!',
          ...toastifyOptions,
        }).showToast()
        waitlistStore.setItem('userInfo', params)
        setUserInfo(params)
      }
    })
  }

  const getProductList = () => {
    const request = new ListProductsRequest()
    client.listProducts(request, {}, (err, res) => {
      if (err?.message) Toastify({...toastifyOptions, text: err.message}).showToast()
      const data = res?.toObject()
      setProductList(data?.productsList ?? [])
    })
  }

  const followUsOnTwitter = () => {
    window.open('https://twitter.com/ifanr')
  }

  return (
    <div className="waitlist-container w-auto min-h-[100vh] flex flex-col items-center bg-[#040419]">
      <header
        className="header sticky top-[0] w-[100vw] pt-[0] pb-[0] pl-[5vw] pr-[5vw] bg-[#040419]"
        // md=""
        xl="pl-[80px] pr-[80px]"
      >
        <div
          className="bottom-line-wrap border-b-[1px] border-[rgba(255,255,255,0.17)] flex items-center bg-[#040419] w-auto h-auto py-[2vw]"
          xl="py-[8px]"
        >
          <div className="waitlist-logo flex items-center">
            <img
              className="emoji mr-[2.5vw] w-[6vw] h-[6vw]"
              xl="mr-[10px] w-[24px] h-[24px]"
              src={`${process.env.PUBLIC_URL}/emoji.png`}
            />
            <span
              className="name font-bold text-[4.5vw] text-white hidden"
              xl="font-bold text-[18px] block"
            >
              Waitlists.ai
            </span>
          </div>
          <div className="header-right ml-auto flex items-center">
            <button
              className="submit-product-button bg-gradient-to-br from-[rgba(54,74,255,0.43)] to-[rgba(45,61,204,0.54)] flex items-center justify-center font-bold text-white w-[40vw] h-[8vw] rounded-[4vw] text-[3.5vw] leading-[4.25vw] mr-[2vw]"
              xl="w-[160px] h-[32px] rounded-[16px] text-[14px] leading-[17px] mr-[20px]"
              onClick={() => setShowModal(true)}
            >
              Submit Your Product
            </button>
            <button
              className="follow-us-button flex items-center w-[8vw] h-[8vw] rounded-[6vw]"
              xl="w-[120px] h-[32px] rounded-[24px] border border-solid border-white border-opacity-50"
              onClick={followUsOnTwitter}
            >
              <img
                alt="twitter-logo"
                className="twitter-logo ml-[3.25vw] mr-[2vw] w-5[.04vw] h-[4.5vw]"
                xl="ml-[15px] mr-[8px] w-[21.6px] h-[18px]"
                src={`${process.env.PUBLIC_URL}/twitter-logo.png`}
              />
              <span className="font-bold text-[3.5vw] text-white hidden" xl="text-[14px] block">
                Follow Us
              </span>
            </button>
          </div>
        </div>
      </header>
      <div className="body flex flex-col items-center mt-[10vw] pl-[5vw] pr-[5vw]" xl="mt-[88px]">
        <div className="feature-waitlist flex items-center mb-[10vw]" xl="mb-[115px]">
          <div className="feature-box flex flex-col" xl="mr-[160px]">
            <div
              className="guide-text w-auto mb-[10vw] text-white font-bold text-[10vw]"
              xl="mb-[40px] w-[480px] h-[144px] text-[40px] leading-[48px] "
            >
              Unlock the Future with One Click: Join the Waitlist for the Hottest New AI Product
              Today!
            </div>
            <form
              className="form flex flex-col items-center mt-[4vw] mb-[4vw] gap-[4vw]"
              xl="mt-[40px] mr-[18px] mb-[40px] gap-[10px]"
              onSubmit={handleSubmit(subscribe)}
            >
              <input
                className="input text-white focus:outline-none pt-[3.5vw] pb-[3.5vw] border border-[2px] border-opacity-35 border-white bg-[#040419] rounded-[24px] w-[80vw] h-[12vw] leading-[19px] text-[4.5vw] font-normal pl-[2vw] pr-[2vw]"
                xl="pt-[14px] pb-[14px] pl-[18px] pr-[18px] text-[16px] w-[231px] h-[48px] rounded-[24px]"
                placeholder="Enter your email…"
                {...register('email', {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors?.email?.type === 'required' && (
                <p className="text-red-500" xl="font-normal text-4.5vw">
                  This field is required
                </p>
              )}
              {errors?.email?.type === 'pattern' && (
                <p className="text-red-500" xl="font-normal text-4.5vw">
                  Invalid email format. Please enter a valid email address.
                </p>
              )}
              <input
                className="input text-white focus:outline-none pt-3.5vw pb-3.5vw border border-[2px] border-opacity-35 border-white bg-[#040419] rounded-[24px] w-[80vw] h-[12vw] leading-[19px] text-4.5vw font-normal pl-[2vw] pr-vw"
                xl="pt-[14px] pb-[14px] pl-[18px] pr-[18px] text-[16px] w-[231px] h-[48px] rounded-[24px]"
                placeholder="Enter your name…"
                {...register('name', {required: true})}
              />
              {errors?.name?.type === 'required' && (
                <p className="text-red-500" xl="font-normal text-4.5vw">
                  This field is required
                </p>
              )}
              <button
                className="submit-button flex justify-center items-center text-white cursor-pointer w-[40vw] h-[12vw] rounded-[8vw] font-bold text-4.5vw leading-[20px]"
                xl="w-[120px] h-[48px] text-[16px] rounded-[32px]"
                type="submit"
              >
                I'm In
              </button>
            </form>
            <span
              className="tips text-opacity-60 text-white text-4.5vw text-4.5vw"
              xl="w-[480px] h-[44px] text-[16px] leading-[22px]"
            >
              Every Product, Handpicked for Excellence: We’re always on the hunt for the newest
              innovations. As we discover and add fresh finds, you’re instantly added to the
              waitlist. Experience the best first, always!
            </span>
          </div>

          <img
            alt="AI-robot"
            className="hidden"
            xl="block w-[401px] h-[401px]"
            src={`${process.env.PUBLIC_URL}/AI-robot.png`}
          />
        </div>
        <div className="feature-explore mb-[24px]">
          <div
            className="feature-text mb-[6vw] text-[8vw] font-bold text-white text-center"
            xl="mb-[24px] text-[32px]"
          >
            Supported Products
          </div>
          <div
            className="feature-sub-text mb-[2vw] font-light text-4.5vw text-4.5vw text-opacity-60 text-white"
            xl="mb-[80px] text-md text-center text-[16px] leading-[22px]"
          >
            Carefully curated products, updated each week.
          </div>
          <div
            className="feature-products grid grid-cols-2 gap-[10vw]"
            xl="grid grid-cols-3 gap-[40px]"
          >
            {productList.map(product => {
              return (
                <div
                  key={uuid()}
                  className="feature-product grid-item w-[40vw] flex flex-col"
                  xl="w-[320px] h-[160px] p-[24px] rounded-[24px] bg-gray-900 border border-gray-800 rounded-[1px]"
                >
                  <div
                    className="feature-product-header bg-gray-900 border border-gray-800 rounded-[1px]"
                    xl="flex bg-[transparent] border-[transparent] rounded-[none]"
                  >
                    <img
                      alt="product-logo"
                      className="hidden"
                      xl="block w-[48px] h-[48px]"
                      src={product.logo}
                    />
                    <div
                      className="product-logo h-24vw bg-cover bg-center bg-no-repeat"
                      xl="w-[48px] h-[48px] bg-left-top bg-auto hidden"
                      style={{backgroundImage: `url(${product.logo})`}}
                    />
                    <div
                      className="product-info-wrap pr-[1.5vw] pl-[1.5vw]"
                      xl="ml-[18px] pr-[0] pl-[0]"
                    >
                      <div
                        className="product-name pt-[1.5vw] pb-[1.5vw] font-medium text-4.5vw text-white"
                        xl="mb-[11px] text-[16px] leading-[20px] p-[24px] pt-[0] pb-[0]"
                      >
                        {product.name}
                      </div>
                      <div
                        className="product-summary pt-[1.5vw] pb-[1.5vw] font-normal text-white"
                        xl="text-[14px] leading-[17px] p-[24px] pt-[0] pb-[0]"
                      >
                        {product.description}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <footer
        className="footer w-full h-[20vw] bg-[#040419] px-[5vw] mt-auto"
        xl="px-[80px] h-[80px]"
      >
        <div
          className="content-disclaimer border-t-[1px] border-[#26262f] w-full h-full flex items-center font-normal text-[3.25vw] leading-[4vw] text-white text-opacity-60"
          xl="text-[13px] leading-[16px]"
        >
          Made by real humans, not robots.
        </div>
      </footer>
      <ProductForm showModal={showModal} onClose={onCloseFormModal} />
    </div>
  )
}

export default App
